import React, { useContext, useEffect } from "react"
import { gsap } from "gsap"
import { Context } from "../../store/ContextProvider"
import "./Hero.scss"
import { Elastic } from "gsap/all"

import { Config } from "../index"

const Hero = () => {
  const {
    state: { isMenuOpen },
  } = useContext(Context)

  const animateHero = () => {
    gsap
      .timeline({
        defaults: {
          ease: Elastic.easeOut.config(0.4, 0.5),
        },
      })
      .set(".hero__wrapper", { scale: 0, autoAlpha: 0, y: 100 })
      .fromTo(
        ".hero__wrapper",
        { scale: 0, autoAlpha: 0, y: 100 },
        { scale: 1, autoAlpha: 1, y: 0 }
      )
  }

  useEffect(() => {
    if (!isMenuOpen) animateHero()
  }, [isMenuOpen])

  return (
    <section className="hero">
      <div className="hero__wrapper">
        <div className="info">
          <div className="name">
            <div className="text-name">{Config.home.name}</div>
          </div>
          <div className="passion">
            <div className="typing-demo text-medium">{Config.home.text}</div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Hero
