import React from "react"
import Hero from "./../components/Hero/Hero"

export const IndexPageTemplate = ({}) => (
  <div className="hero">
    <Hero />
  </div>
)

export default IndexPageTemplate
